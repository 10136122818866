<template>
  <div class="member-report">
    <!-- 顶部内容 -->
    <div class="member-report-top">
      <!-- id -->
      <div class="one-line">
        <label for="contactName">Id ：</label>
        <input
          type="text"
          id="contactName"
          v-model.trim="searchId"
          clearable
        >
      </div>
      <!-- 撰写人 -->
      <div class="write-line">
        <label for="userName">{{ $t('Writer') }}：</label>
        <input
          type="text"
          id="userName"
          v-model.trim="userName"
          clearable
        >
      </div>

      <!-- 创建时间 -->
      <div class="times-line">
        <label>{{ $t('CreateTime') }}：</label>
        <el-date-picker
          v-model="createTime"
          type="date"
          value-format="yyyy-MM-dd"
          :placeholder="$t('ChooseDate')"
          style="float: left;"
        >
        </el-date-picker>
      </div>

      <!-- 搜索 -->
      <div class="search-line">
        <el-button @click="searchRepotManagement">{{ $t('Search') }}</el-button>
        <!-- <input
          type="button"
          class="sousuo-line"
          value="搜索"
          @click="searchRepotManagement"
        > -->
      </div>
    </div>

    <!-- 二级导航栏 -->
    <div class="member-report-part">
      <div class="member-report-content">
<!--        <div @click="select1('')" :class="inintReportManagement.status===''?'active':''">{{ $t('AllData') }}</div>
        <div @click="select1(1)" :class="inintReportManagement.status===1?'active':''">{{ $t('AllData') }}</div>
        <div @click="select1(0)" :class="inintReportManagement.status===0?'active':''">{{ $t('Passed') }}</div>
        <div @click="select1(3)" :class="inintReportManagement.status===3?'active':''">{{ $t('Reject') }}</div>-->
        <div @click="select1(0)" :class="{'active':inintReportManagement.status===0}">{{ $t('AllData') }}</div>
        <div @click="select1(2)" :class="{'active':inintReportManagement.status===2}">{{ $t('Auditing') }}</div>
        <div @click="select1(1)" :class="{'active':inintReportManagement.status===1}">{{ $t('Passed') }}</div>
        <div @click="select1(3)" :class="{'active':inintReportManagement.status===3}" style="border-right:none">{{ $t('Reject') }}</div>
      </div>
    </div>

    <!-- 表格数据 -->

    <el-table
      border
      :data="tableData"
      class="table-border"
    >
      <!-- :cell-style="getColor" -->
      <el-table-column
        prop="reportId"
        label="id"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="reportTitle"
        :label="$t('Title')"
        width="480"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="userName"
        :label="$t('Writer')"
        width="220"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$t('CreateTime')"
        width="240"
        align="center"
      >
      </el-table-column>
      <el-table-column
        :label="$t('Status')"
        prop="status"
        width="240"
        align="center"
      >
        <template slot-scope="scope">
          <span :style="getColor[scope.row.status]">{{ getStatus(scope.row.status)}}</span>
          <!-- <span
            v-if="scope.row.status==='自动通过'"
            class="auto-pass"
          >{{ scope.row.status }}</span>
          <span
            v-else-if="scope.row.status==='人工通过' "
            class="person-pass"
          >{{ scope.row.status }}</span>
          <span
            v-else-if="scope.row.status==='已驳回' "
            class="rejected"
          >{{ scope.row.status}}</span>
          <span
            v-else-if="scope.row.status==='有敏感词' "
            class="sensitive-words"
          >{{ scope.row.status}}</span> -->
        </template>
        <!-- <template scope="scope"> -->
        <!-- 意思是：给这个div绑定动态样式，颜色color的属性值为getColor()这个方法的返回值，所以只需要通过传过去的scope 对方法的返回值做动态设置即可  -->
        <!-- <div :style="{color:getColor(scope)}">{{scope.row.status}}</div>
        </template> -->
      </el-table-column>
      <el-table-column
        :label="$t('Operation')"
        width="213"
        align="center"
      >
        <template slot-scope="scope">
          <!--任何情况   详情-->
          <el-button
            size="mini"
            type="text"
            @click="industryDetails(scope.row)"
          >{{ $t('Detail') }}</el-button>
          <!--通过状态   撤回-->
          <el-button
            v-if="scope.row.status===0||scope.row.status===1"
            size="mini"
            type="text"
            v-bind:style=" SystemCancellation"
            @click="cancel(scope.row.reportId)"
          >{{ $t('RevokeReport') }}</el-button>
          <!--待审核状态  通过-->
          <el-button
            v-if="scope.row.status===2"
            size="mini"
            type="text"
            @click="passReport(scope.row.reportId)"
          >{{ $t('Pass') }}</el-button>
          <!--待审核状态   拒绝-->
          <el-button
            v-if="scope.row.status===2"
            size="mini"
            type="text"
            @click="sendmessage(scope.row.reportId)"
            v-bind:style=" refuse"
          > {{ $t('Refuse') }}</el-button>
          <!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
          <!-- <el-button size="mini" type="danger" @click="handleRemove(scope.$index, scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="fen-pages">
      <el-pagination
        background
        :current-page="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="postTotal"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/fetch/api";
export default {
  name: "ReportManagement",
  components: {},
  data() {
    return {
      // 通过状态改变字体颜色 数组
      getColor: [
        "color: #CBA43F",
        "color: #81B337",
        "color: red",
        "color: #BD3124",
      ],
      // 搜索绑定的id属性 搜索后续传值
      searchId: "",
      //搜索绑定的撰写人属性
      userName: "",
      // 索引点击全选到被撤销的下标
      idx1: 0,
      // 创建时间绑定的值
      createTime: "",
      // 空数组 用来保存分页的数据
      tableDatas: [],
      // 帖子信息列表总条数 总条目数
      postTotal: 0,
      // 当前页数
      currentPage: 1,
      index: 0,
      //显示的页数
      pageSize: 10,
      // 是否弹出拒绝框
      // getmessage: false,
      // 详情按钮样式
      detailedInformation: {
        backgroundColor: "white",
        color: "#004DA1",
      },
      // 撤销
      SystemCancellation: {
        color: "red",
      },
      // 拒绝
      refuse: {
        color: "red",
      },
      // 通过
      PassThrough: {
        backgroundColor: "white",
        color: "black",
      },
      //表格数据
      tableData: [],
      // 行业报告传值
      inintReportManagement: {
        reportId: "",
        userName: "",
        createTime: "",
        status: 0, // 0自动过 1人工过 2待审核(有敏感词) 3被拒绝     //0全部 1自动通过/人工通过 2有敏感词 3不通过
        page: 1,
      },
    };
  },
  created() {},
  mounted() {
    this.initReportManagementData(1);
  },
  methods: {
    // 传status：  0全部 1自动通过/人工通过 2有敏感词 3已拒绝
    // 获取时的status：    0自动过 1人工过 2待审核(有敏感词) 3被拒绝
    initReportManagementData(currentPage) {
      // 传值
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.id;
      const params = {
        userId: currentAdminId,
        currentAdmin: currentAdminId,
        page: currentPage,
        pageSize:5,
      };

      // id  js转换， '',0,NaN,null,undefined转换为false，常用if条件判断
      //id文本框绑定一个值，判断是否输入了值，
      //如果有，就在接口参数中加对应的字段，没有接口参数中就不写
      if (this.searchId) {
        params.reportId = this.searchId;
      }
      //撰写人
      if (this.userName) {
        params.userName = this.userName;
      }
      //创建时间
      if (this.createTime) {
        params.createTime = this.createTime;
      }
      //二级导航栏
      if (this.inintReportManagement.status !== 0) {
        params.status = this.inintReportManagement.status;
      }
      /**
       * 获取行业报告列表
       */
      console.log(params)
      api.getReportList(params).then((res) => {
        /*if (this.inintReportManagement.status === 0) {
          params.status = 1
          api.getReportList(params).then((result) => {
            this.tableData = [...res.data.pageList, ...result.data.pageList];
            this.postTotal = res.data.total + result.data.total;
          })
        } else {
          this.tableData = res.data.pageList;
          this.postTotal = res.data.total;
        }*/
        this.tableData = res.data.pageList
      });
    },

    // 实现搜索功能
    searchRepotManagement() {
      //id
      this.searchId
        ? (this.inintReportManagement.reportId = this.searchId)
        : delete this.inintReportManagement.reportId;
      //撰写人
      this.userName
        ? (this.inintReportManagement.userName = this.userName)
        : delete this.inintReportManagement.userName;
      //创建时间
      this.createTime
        ? (this.inintReportManagement.createTime = this.createTime)
        : delete this.inintReportManagement.createTime;
      this.currentPage = 1;
      // console.log("searchid测试", this.searchId);
      this.initReportManagementData(1);
    },
    // 定义一个对象，把对象存到sessionStorage里
    // 跳转详情页面 组件传值 缓存到本地
    industryDetails(row) {
      // 缓存页面传值
      /*const obj = {
        createTime: row.createTime,
        fileAppendixList: row.fileAppendixList,
        reportDetail: row.reportDetail,
        reportId: row.reportId,
        reportTitle: row.reportTitle,
        status: row.status,
        userName: row.userName,
      };
      //JSON.stringify(obj)一个表示给定值的JSON字符串
      sessionStorage.setItem("obj", JSON.stringify(obj));*/
      this.$router.push({
        name: "ReportDetail" ,
        query:{
          reportId:row.reportId
        }
      });
    },

    // 点击切换效果 全部-被撤销
    select1(i) {
      this.inintReportManagement.status = i;
      // this.$nextTick(() => {
        this.initReportManagementData(1);
      // })
    },
    //通过状态显示字体
    getStatus(i) {
      if (i === 0) {
        return this.$t("AutoPass");
      } else if (i === 1) {
        return this.$t("ManPass");
      } else if (i === 2) {
        return this.$t("Sensitive");
      } else if (i === 3) {
        return this.$t("Reject");
      }
    },

    // 撤销功能
    async cancel(id) {
      console.log(id);
      // if (confirm(this.$t("ComfirmDelete"))) {
      //   const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
      //     .adminData.id;
      //   const params = {
      //     currentAdmin: currentAdminId,
      //   };
      //   params.reportId = id;
      //   api.getUpdateById(params).then((res) => {
      //     console.log("res.msg测试成功", res.msg);
      //     this.initReportManagementData(1);
      //   });
      // }

      const confirmResult = await this.$confirm(
        this.$t('WithdrawAlert'),
        this.$t('Attention'),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancle'),
          type: "warning",
        }
      ).catch((error) => error);
      // 进行判断拿confirmResult看是否等于confirm不能于则是取消
      if (confirmResult !== "confirm") {
        return this.$message.info(this.$t('Canceled'));
      }
      // 发起请求 传参
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.id;
      const params = {
        currentAdmin: currentAdminId,
      };
      params.reportId = id;
      api.getUpdateById(params).then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('SysBusy'));
        }
        // 成功给个提示信息
        this.$message.success(this.$t('Withdrew'));
        // 刷新页面调用获取表格数据的方法 getUserList()
        // 刷新页面
        this.initReportManagementData(1);
      });
    },

    //分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initReportManagementData(currentPage);
    },

    // 拒绝
    async sendmessage(id) {
      console.log(id);
      const confirmResult = await this.$confirm(
        this.$t('RefuseAlert'),
        this.$t('Attention'),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancle'),
          type: "warning",
        }
      ).catch((error) => error);
      // 进行判断拿confirmResult看是否等于confirm不能于则是取消
      if (confirmResult !== "confirm") {
        return this.$message.info(this.$t('Canceled'));
      }
      // 发起请求 传参
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.id;
      const params = {
        currentAdmin: currentAdminId,
      };
      params.reportId = id;
      params.status = 3;
      api.getAuditById(params).then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('SysBusy'));
        }
        // 成功给个提示信息
        // this.$message.success("拒绝成功");
        // 刷新页面调用获取表格数据的方法 getUserList()
        // 刷新页面
        this.initReportManagementData(1);
      });
    },

    // 通过
    async passReport(id) {
      // console.log(id);
      // 发起请求 传参
      const currentAdminId = JSON.parse(localStorage.getItem("cacheChamber"))
        .adminData.id;
      const params = {
        currentAdmin: currentAdminId,
      };
      params.reportId = id;
      params.status = 1;
      api.getAuditById(params).then((res) => {
        if (res.code !== 200) {
          this.$message.error(this.$t('SysBusy'));
        }
        // 成功给个提示信息
        // this.$message.success("通过成功");
        // 刷新页面调用获取表格数据的方法 getUserList()
        // 刷新页面
        this.initReportManagementData(1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.member-report {
  width: 1600px;
  margin: -10px;
  .member-report-top {
    width: 99%;
    height: 50px;
    margin: 5px auto 10px auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #677d95;
    border-radius: 5px;
    color: white;
    padding-top: 5px;
    input {
      //border: 1px solid red;
      min-width: 200px;
      border: 1px solid white;
      margin: 8px 0;
      float: left;
    }
    div label {
      margin: 8px 0 8px 10px;
      display: block;
      float: left;
    }

    .el-button {
      margin: 5px 10px;
      width: 200px;
      height: 30px;
      line-height: 0.5px;
      background-color: #677d95;
      color: white;

      &:hover {
        background-color: #9fbee6 !important;
      }
    }
  }

  // 点击筛选样式
  .member-report-part {
    font-size: 14px;
    min-height: 60px;
    width: 99%;
    text-align: center;
    line-height: 55px;
    margin-left: 10px;
    margin-top: 10px;
    .member-report-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      height: 40px;
      line-height: 40px;
      border: 1px solid #060606;
      div{
        border-right: 1px solid black;
      }
      /*.all {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .pass {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }
      .cancelled {
        flex: 1;
        border-right: 1px solid #6e6e6e;
      }*/
      .active {
        background-color: #677d95;
        color: white;
      }
    }
  }

  // 表格样式
  .table-border {
    border: 1px solid whitesmoke;
    width: 99%;
    margin: 8px auto;
    margin-left: 10px;
    .auto-pass {
      color: #cba43f;
    }
    .person-pass {
      color: #b6c437;
    }
    .rejected {
      color: #951d1d;
    }
    .sensitive-words {
      color: #951d1d;
    }
  }

  .fen-pages {
    margin-top: 10px;
  }
}
</style>

<style>
.el-tooltip__popper {
    max-width: 800px;
}

.el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden !important;
}
</style>